<template>
  <div>
    <fp-select
      class="eh-web-app-flag-selector"
      v-model="preferedLanguage"
      @change="changeLanguage"
      valueKey="Value"
      :items="Object.values($enums.Languages)"
    >
      <template #element="flag">
        <img class="eh-web-app-header-flag-img" :src="getImgUrl(flag.flag)" />
        <span class="eh-web-app-header-flag-text">{{ flag.Text }}</span>
      </template>
    </fp-select>
  </div>
</template>
<script>
import { UserProfileLogic } from "@/Logic/Backend/user-profile";
export default {
  name: "LangSwitch",
  data() {
    return {
      preferedLanguage: this.$i18n.locale,
    };
  },
  methods: {
    async changeLanguage(lang) {
      sessionStorage.setItem("FP_LL", lang);
      if (this.$i18n.locale != lang) {
        this.$i18n.locale = lang;
        await UserProfileLogic.setUserProfile(
          { "PersonalProfile.PreferredLanguage": lang },
          this.$loggedUser.UserId
        );
      }
    },
    getImgUrl(url) {
      return require("@/Assets" + url.toString());
    },
  },
};
</script>
